import { sendMergeToGate } from "./request";
import { internals, moduleLogger } from "./util";
import { get as getFragment } from "@otto-ec/global-resources/fragment";
import { get as isToggleEnabled } from "@otto-ec/global-resources/toggle";

const log = moduleLogger.scope("external-call-tracking");

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function init() {
  if (internals.TRACKING_echinitialized) {
    return;
  }
  internals.TRACKING_echinitialized = true;

  const track = isToggleEnabled("tr_track_external_call_time", false);

  if (track) {
    trackExternalCallHandled();
  }
}

function trackExternalCallHandled() {
  /*                                             */
  const echHash = getFragment("ech");
  try {
    if (!echHash) {
      return;
    }
    sendMergeToGate({ ts_Ech: [echHash] }, "default");
  } catch (e) {
    log.error(`ECH-Tracking failed: '${e}'`);
  }
}
