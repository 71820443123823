import * as util from "./util";
import { sendEventToGate, sendMergeToGate } from "./request";
import { Storage } from "@otto-ec/global-resources/storage";
import { isPlainObject, isEmptyObject } from "@otto-ec/global-resources/core";
import { delegate } from "@otto-ec/global-resources/event";
import { DataContainer, parseDataContainer } from "./datacontainer";
import { get as isToggleEnabled } from "@otto-ec/global-resources/toggle";

const ATTRIBUTE_NAME = "ts-link";
const STORAGE_KEY = "ts-linkdata";

const storage = new Storage(window.sessionStorage);

/**
 *
 *
 *
 */
function load(): DataContainer {
  const EMPTY_LINK_DATA = {};

  try {
    const linkDataFromSessionStore = storage.getItem(STORAGE_KEY);

    if (linkDataFromSessionStore === null) {
      return EMPTY_LINK_DATA;
    }

    return JSON.parse(linkDataFromSessionStore.toString());
  } catch (e) {
    return EMPTY_LINK_DATA;
  }
}

/**
 *
 *
 *
 */
function store(dataContainer: DataContainer): boolean {
  const currentLinkData = load();
  const newLinkData = {
    ...currentLinkData,
    ...dataContainer,
  };

  return storage.setItem(STORAGE_KEY, JSON.stringify(newLinkData));
}

function isLinkDataDefined(linkData: DataContainer): boolean {
  return isPlainObject(linkData) && !isEmptyObject(linkData);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function trackOnNextPageImpression(dataContainer: DataContainer): boolean {
  const parsed = parseDataContainer(dataContainer);
  if (parsed) return store(parsed);
  else return false;
}

/**
 *
 *
 *
 */
export function pop(): DataContainer {
  const linkData = load();

  if (isLinkDataDefined(linkData)) {
    linkData.ts_BctFromBrowserStore = ["true"];
    storage.removeItem(STORAGE_KEY);
  }

  return linkData;
}

export function init() {
  if (util.internals.TRACKING_linkdatainitialized) {
    return;
  }

  util.internals.TRACKING_linkdatainitialized = true;

  for (const eventType of ["click", "auxclick"] as const) {
    if (eventType === "click" || isToggleEnabled("tr_handle_auxclick", false)) {
      /**
 *
 *
 *
 */
      delegate(document, eventType, "a.ts-link:not([target=_blank])", (event: MouseEvent) => {
        const anchor = event.currentTarget as HTMLAnchorElement;

        const linkData = util.getLinkData(anchor, ATTRIBUTE_NAME);

        if (linkData !== null) {
          store(parseDataContainer(linkData));
        }
      });

      /**
 *
 *
 */
      delegate(document, eventType, "a[target=_blank]", (event: MouseEvent) => {
        const target = event.currentTarget as HTMLAnchorElement;

        const linkData = util.getLinkData(target, ATTRIBUTE_NAME);
        const storedLinkData = pop();

        if (!linkData && !isLinkDataDefined(storedLinkData)) {
          return;
        }

        const labels = {
          ot_LinkTarget: ["extern"],
          ...(linkData ? parseDataContainer(linkData) : {}),
          ...storedLinkData,
        };

        sendEventToGate(labels);
      });
    }
  }

  /*                                            */
  const linkData = pop();
  if (linkData !== null) {
    sendMergeToGate(linkData, "default");
  }
}
