import { sendMergeToGate } from "./request";
import { get as toggleEnabled } from "@otto-ec/global-resources/toggle";
import { get as getCookie, remove as removeCookie } from "@otto-ec/global-resources/cookie";
import { cmp } from "@otto-ec/eprivacy-cmp";
import { moduleLogger } from "./util";

const log = moduleLogger.scope("nitro");

function includeScript(source: string, callback: () => void) {
  window.nitro = window.nitro || {};
  window.nitro.cb = callback;
  const nitroScriptTag = document.createElement("script");
  const firstScriptTagInDom = document.getElementsByTagName("script")[0];
  nitroScriptTag.async = true;
  nitroScriptTag.src = source;
  firstScriptTagInDom?.parentNode?.insertBefore(nitroScriptTag, firstScriptTagInDom);
}

/**
 *
 *
 *
 */
export function shouldLoadNitro(): Promise<boolean> {
  const nitroJsEnabled = toggleEnabled("nitro_js_enabled", false);
  if (!nitroJsEnabled) return Promise.resolve(false);
  return Promise.all([
    cmp.readVendorsConsents(),
    cmp.getConsentForGV("ADVMK"),
    cmp.getConsentForGV("OTTOT"),
  ]).then(
    ([vendors, advmk, ottot]) => {
      /*                        */
      const vendor44Given = vendors.get(44);
      return !!advmk && !!ottot && !!vendor44Given;
    },
    () => false,
  );
}

const datenschutzPaths = ["/shoppages/service/datenschutz", "/datenschutz"];

/**
 *
 *
 *
 */
export function isOnDatenschutzPage(currentPathname: string): boolean {
  return datenschutzPaths.some((path) => currentPathname.substring(0, path.length) === path);
}

export default function init() {
  try {
    removeFalsyNitroCookie();
  } catch (error) {
    log.error(error);
  }

  if (isOnDatenschutzPage(document.location.pathname)) {
    return;
  }

  shouldLoadNitro().then((value) => {
    if (value) {
      includeScript("https:/*                                                           */
        sendMergeToGate({ ot_NewNitroCookie: ["true"] });
      });
    }
  });
}

/**
 *
 *
 *
 */
export function removeFalsyNitroCookie() {
  const cookie = getCookie("ADS_NITRO");

  if (!cookie || cookie !== "[object Object]") {
    return;
  }

  const domain = location.hostname === "localhost" ? "" : ".otto.de";
  removeCookie("ADS_NITRO", { path: "/", domain });
}
 
