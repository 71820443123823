import { submitMove, submitMoveWithAction } from "./move";
import {
  sendEventActionToGate,
  sendEventToGate,
  sendMergeToGate,
  sendMiniActionsToGate,
} from "./request";
import { getMergeParameters, getPageId } from "./url-helper";
import { createEventMergeContext, replaceContext, createContext, closeContext } from "./bct";
import { trackOnNextPageImpression } from "./link-data";
import { get as toggleActive } from "@otto-ec/global-resources/toggle";
import { events, eventQBus as untypedEventQBus } from "@otto-ec/global-resources/event-q-bus";
import { Action, Feature, MiniAction } from "./feature-typing";
import { TrackingQBusEvents } from "../event-q-bus";
import type { DataContainer } from "./datacontainer";
import type { OnLateMergeStrategy } from "./request-typings";

const eventQBus = events<TrackingQBusEvents>();

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
const topics = {
  submitEvent: "tracking.bct.submitEvent",
  addActionToEvent: "tracking.bct.addActionToEvent",
  addToPageImpression: "tracking.bct.addToPageImpression",
  addToPageImpressionWithStrategy: "tracking.bct.addToPageImpressionWithStrategy",
  addToEvent: "tracking.bct.addToEvent",
  getMergeParameters: "tracking.bct.getMergeParameters",
  getPageId: "tracking.bct.getPageId",
  createEventMergeContext: "tracking.bct.createEventContext",
  replaceContext: "tracking.bct.replaceContext",
  createContext: "tracking.bct.createContext",
  closeContext: "tracking.bct.closeContext",
  submitMove: "tracking.bct.submitMove",
  submitMoveAction: "tracking.bct.submitMoveAction",
  addFeaturesToPageImpression: "tracking.bct.addFeaturesToPageImpression",
  submitAction: "tracking.bct.submitAction",
  trackOnNextPageImpression: "tracking.bct.trackOnNextPageImpression",
  submitMiniActions: "tracking.bct.submitMiniActions",
} as const;

function onSubmitMove(
  dataContainer: DataContainer,
  responseEventName?: `${string}.${string}.${string}`,
) {
  const moveResponse = submitMove(dataContainer);
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, moveResponse);
  }
}

function onSubmitMoveAction(
  dataContainer: DataContainer,
  action: Action,
  responseEventName?: `${string}.${string}.${string}`,
) {
  const moveResponse = submitMoveWithAction(dataContainer, action);
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, moveResponse);
  }
}

function onSubmitEvent(
  dataContainer: DataContainer,
  responseEventName?: `${string}.${string}.${string}`,
) {
  const eventResponse = sendEventToGate(dataContainer);
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, eventResponse);
  }
}

function onAddActionToEvent(
  actionContainer: Action,
  mergeId: string,
  responseEventName?: `${string}.${string}.${string}`,
) {
  const actionResponse = sendEventActionToGate({}, actionContainer, mergeId);
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, actionResponse);
  }
}

function onSubmitAction(
  dataContainer: DataContainer,
  action: Action,
  responseEventName?: `${string}.${string}.${string}`,
) {
  const actionResponse = sendEventActionToGate(dataContainer, action);
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, actionResponse);
  }
}

function onCreateEventContext(responseEventName?: `${string}.${string}.${string}`) {
  const eventContext = createEventMergeContext();
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, eventContext);
  }
}

function onGetMergeParameters(responseEventName?: `${string}.${string}.${string}`) {
  const mergeParameters = getMergeParameters();
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, mergeParameters);
  }
}

function onGetPageId(responseEventName?: `${string}.${string}.${string}`) {
  const pageId = getPageId();
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, pageId);
  }
}

function onReplaceContext(
  id: string,
  url: string,
  dataContainer: DataContainer,
  responseEventName?: `${string}.${string}.${string}`,
) {
  const currentContextStack = replaceContext(id, url, dataContainer);
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, currentContextStack);
  }
}

function onCreateContext(
  id: string,
  url: string,
  dataContainer?: DataContainer,
  responseEventName?: `${string}.${string}.${string}`,
) {
  const currentContextStack = createContext(id, url, dataContainer);
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, currentContextStack);
  }
}

function onCloseContext(responseEventName?: `${string}.${string}.${string}`) {
  const closedContext = closeContext();
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, closedContext);
  }
}

function onAddToEvent(
  dataContainer: DataContainer,
  eventMergeId: string | undefined,
  responseEventName?: `${string}.${string}.${string}`,
) {
  const eventMergeResponse = sendEventToGate(dataContainer, eventMergeId);
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, eventMergeResponse);
  }
}

function onMerge(
  dataContainer: DataContainer,
  responseEventName?: `${string}.${string}.${string}`,
) {
  const mergeResponse = sendMergeToGate(dataContainer, "default");
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, mergeResponse);
  }
}

function onStrategicMerge(
  dataContainer: DataContainer,
  onLate: OnLateMergeStrategy,
  responseEventName?: `${string}.${string}.${string}`,
) {
  const mergeResponse = sendMergeToGate(dataContainer, onLate);
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, mergeResponse);
  }
}

function onTrackOnNextPageImpression(
  dataContainer: DataContainer,
  responseEventName?: `${string}.${string}.${string}`,
) {
  const storeResponse = trackOnNextPageImpression(dataContainer);
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, storeResponse);
  }
}

function onMiniAction(
  updates: Array<MiniAction>,
  responseEventName?: `${string}.${string}.${string}`,
) {
  const updateResponse = sendMiniActionsToGate(updates);
  if (responseEventName) {
    untypedEventQBus.emit(responseEventName, updateResponse);
  }
}

/**
 *
 *
 *
 *
 */
function onAddFeaturesToPageImpression(
  features: Array<Feature>,
  responseEventName?: `${string}.${string}.${string}`,
) {
    const response = sendMergeToGate({}, "default", features);
    if (responseEventName) {
      untypedEventQBus.emit(responseEventName, response);
    }
  }

/**
 *
 *
 *
 */
export default function init(): () => void {
  if (toggleActive("tr_add_mini_actions", false)) {
    eventQBus.on(topics.submitMiniActions, onMiniAction);
  }

  eventQBus.on(topics.submitMove, onSubmitMove);
  eventQBus.on(topics.submitMoveAction, onSubmitMoveAction);
  eventQBus.on(topics.addToEvent, onAddToEvent);
  eventQBus.on(topics.addToPageImpression, onMerge);
  eventQBus.on(topics.addToPageImpressionWithStrategy, onStrategicMerge);
  eventQBus.on(topics.closeContext, onCloseContext);
  eventQBus.on(topics.createContext, onCreateContext);
  eventQBus.on(topics.createEventMergeContext, onCreateEventContext);
  eventQBus.on(topics.submitEvent, onSubmitEvent);
  eventQBus.on(topics.getMergeParameters, onGetMergeParameters);
  eventQBus.on(topics.getPageId, onGetPageId);
  eventQBus.on(topics.addFeaturesToPageImpression, onAddFeaturesToPageImpression);
  eventQBus.on(topics.submitAction, onSubmitAction);
  eventQBus.on(topics.addActionToEvent, onAddActionToEvent);
  eventQBus.on(topics.trackOnNextPageImpression, onTrackOnNextPageImpression);
  eventQBus.on(topics.replaceContext, onReplaceContext);

  return () => {
    eventQBus.off(topics.submitMove, onSubmitMove);
    eventQBus.off(topics.submitMoveAction, onSubmitMoveAction);
    eventQBus.off(topics.addToEvent, onAddToEvent);
    eventQBus.off(topics.addToPageImpression, onMerge);
    eventQBus.off(topics.addToPageImpressionWithStrategy, onStrategicMerge);
    eventQBus.off(topics.closeContext, onCloseContext);
    eventQBus.off(topics.createContext, onCreateContext);
    eventQBus.off(topics.createEventMergeContext, onCreateEventContext);
    eventQBus.off(topics.submitEvent, onSubmitEvent);
    eventQBus.off(topics.getMergeParameters, onGetMergeParameters);
    eventQBus.off(topics.getPageId, onGetPageId);
    eventQBus.off(topics.addFeaturesToPageImpression, onAddFeaturesToPageImpression);
    eventQBus.off(topics.submitAction, onSubmitAction);
    eventQBus.off(topics.addActionToEvent, onAddActionToEvent);
    eventQBus.off(topics.trackOnNextPageImpression, onTrackOnNextPageImpression);
    eventQBus.off(topics.replaceContext, onReplaceContext);
  };
}
